<template>
  <div id="info-progress">
		<div class="p-4 py-4 mt-6">
			<div class="has-text-centered is-hidden-desktop mb-2">
				<b-button @click="open = !open" outlined
					id="btn-info-progress"
					type="is-success"
				>
					Terjawab (Answered) {{questionProgress}}
				</b-button>
			</div>
			<div v-if="open">
				<b-pagination
					:current.sync="currentPageSidebar"
					:total="section.counter"
					:per-page="section.questionToDisplay"
					aria-next-label="Next page"
					aria-previous-label="Previous page"
					aria-page-label="Page"
					aria-current-label="Current page"
					size="is-small"
					simple
				></b-pagination>
				<hr>
				<div class="columns">
					<div class="column is-12">
						<div class="buttons">
							<b-button v-for="(question, i) in section.questionList" :key="i"
								size="is-small" rounded
								:type="buttonType(question)"
								@click="scrollToQuestion(question)"
								v-show="
									section.questionToDisplay * (currentPageSidebar - 1) < question.index &&
									section.questionToDisplay * currentPageSidebar >= question.index
								">
								{{question.index}}
							</b-button>
						</div>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column is-12 legend">
						<p><b-button type="is-light" size="is-small" rounded>{{totalUnanswered}}</b-button> Kosong (Unanswered)</p>
						<p><b-button type="is-hcc" size="is-small" rounded>{{totalAnswered}}</b-button> Terjawab (Answered)</p>
						<p><b-button type="is-warning" size="is-small" rounded>{{totalMarkedQuestion}}</b-button> Periksa kembali (Check Again)</p>
					</div>
				</div>
				<hr>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  props: {
		section: {
      default: () => {},
    },
		currentPage: {
			default: () => 1
		}
	},
  data() {
    return {
      open: true,
			currentPageSidebar: 1,
    }
  },
	watch:{
		currentPage(val){
			this.currentPageSidebar = val
		},
		currentPageSidebar(val){
			this.$emit('change-page', val)
		}
	},
  computed:{
		questionProgress(){
			let total = this.section.counter
			let totalAnswered = this.section.questionList.filter(question => question.hasAnswer == true).length
			return totalAnswered +' / ' + total
		},
		totalUnanswered(){
			return this.section.questionList.filter(question => question.hasAnswer == false).length
		},
		totalAnswered(){
			return this.section.questionList.filter(question => question.hasAnswer == true).length
		},
		totalMarkedQuestion(){
			return this.section.questionList.filter(question => question.checkLater == true).length
		},
	},
	methods:{
		openSidebar(){
			this.open = true
		},
		closeSidebar(){
			this.open = false
		},
		scrollToQuestion(question){
			setTimeout(()=>{
				this.$emit('uncheck-later', question)
			}, 1500)

			document.getElementById('question-'+question.id)
				.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
		},
		buttonType(question){
			if(question.checkLater) return 'is-warning'
			else if(question.hasAnswer) return 'is-hcc'
			else return 'is-light'
		}
	},
	mounted(){
		window.addEventListener('resize', () => {
			this.open = window.innerWidth > 768
		})
	}
}
</script>

<style scoped>
div#info-progress {
	max-height: 90vh;
	overflow-x: clip;
	overflow-y: overlay;
}
.pagination small.info {
	width: 100%;
	text-align: center;
}
#info-progress .legend{
  font-size: 0.75rem;
}
#info-progress .legend p{
    display: flex;
    align-items: center;
}
#info-progress .legend button{
  font-size: 0.75rem;
	padding: 0 10px;
	margin-right: 3px;
}
@media screen and (min-width: 1024px){
	#info-progress{
		position: fixed;
		width: 16.66666674%;
  }
	
	#info-progress .legend{
    margin-bottom: 50px;
	}
}
</style>